import { render, staticRenderFns } from "./P2PDestinationData.vue?vue&type=template&id=2887625e&scoped=true"
import script from "./P2PDestinationData.vue?vue&type=script&lang=ts"
export * from "./P2PDestinationData.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2887625e",
  null
  
)

export default component.exports